import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router, ActivatedRoute  } from '@angular/router';
import * as uri from '@app/properties.json';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private route: ActivatedRoute) {}    
    canActivate() {               
        var urlParams = new URLSearchParams(window.location.search);        
        if (urlParams.has('dni')) {
            var dni = urlParams.get('dni');
            sessionStorage.removeItem('currentUser');            
            this.router.navigate(['/login/activeUser/'+dni]);    
        }else if(urlParams.has('passRecovery')){
            var passRecovery = urlParams.get('passRecovery');
            this.router.navigate(['/login/recoveryPassUser/'+passRecovery]); 
        }else if((this.router.url == '/' && !sessionStorage.getItem('isLoggedin')) && !sessionStorage.getItem('canLogin')){               
            this.router.navigate(['/welcome']);               
        }else{            
            if (sessionStorage.getItem('isLoggedin')) {
                return true;
            }   
        }             
        return false;
    }
}
