import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatDeudasComponent } from '@app/shared/modules/stat-deudas/stat-deudas.component';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [CommonModule, RouterModule, FontAwesomeModule, TranslateModule],
    declarations: [StatDeudasComponent],
    exports: [StatDeudasComponent, RouterModule]
})
export class StatDeudasModule {}
