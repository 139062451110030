import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router, ActivatedRoute  } from '@angular/router';

@Injectable()
export class LoginGuard implements CanActivate {
    constructor(private router: Router, private route: ActivatedRoute) {}    
    canActivate() {               

        var url_string = window.location.href
        var url = new URL(url_string);
        var active = url.searchParams.get("active");   
                
        if(sessionStorage.getItem('canLogin') == "true" || active == "true"){
        
            return true;
        }else{    

            this.router.navigate(['/welcome']);               
            
        }             
        return false;
    }
}