import { Injectable} from '@angular/core';
import { Observable, Subject, Subscription, BehaviorSubject, timer} from 'rxjs';
import { Router, ActivatedRoute  } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class TimeOutService {
    
    private _count: number = 0;
    private _serviceId: string = 'idleTimeoutSvc-' + Math.floor(Math.random() * 100000);
    private _timeoutSeconds: number = 5;
    private timerSubscription: Subscription;
    private timer: Observable<number>;
    private resetOnTrigger: boolean = false;
    public timeoutExpired: Subject<number> = new Subject<number>();
    
    constructor(public router: Router, public cookieService: CookieService) {     
        this.timeoutExpired.subscribe(n => {            
        });
        this.startTimer();
    }
    public startTimer() {
        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
        }        
        this.timer = timer(this._timeoutSeconds * 100000);
        this.timerSubscription = this.timer.subscribe(n => {
            this.timerComplete(n);
        });
    }

    public stopTimer() {
        this.timerSubscription.unsubscribe();
    }
     
    public resetTimer() {
        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
        }     
        this.timer = timer(this._timeoutSeconds * 100000);
        this.timerSubscription = this.timer.subscribe(n => {
            this.timerComplete(n);
        });
    }

    private timerComplete(n: number) {
        if (sessionStorage.getItem('isLoggedin')) {
            this.timeoutExpired.next(++this._count); 
            this.router.navigate(['/login']);
            localStorage.clear();
            sessionStorage.clear();
            this.cookieService.delete('token');
            if (this.resetOnTrigger) {
                localStorage.clear();
                sessionStorage.clear();
                this.cookieService.delete('token');
                this.startTimer();
            }
        }
    }
}