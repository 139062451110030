import { Component, OnInit, HostListener } from '@angular/core';
import { TimeOutService } from '@app/services/timeout.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
    constructor(private timeoutService: TimeOutService) {
    }

    ngOnInit() {        
    }
    Subscription
    @HostListener('document:click', ['$event'])
    @HostListener('document:keyup', ['$event'])
    @HostListener('document:wheel', ['$event'])
    resetTimer (newEndTime) {        
        if(sessionStorage.getItem('isLoggedin')){
            this.timeoutService.startTimer();
            this.timeoutService.resetTimer();            
        }        
    }  
}
