import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from '@app/app.component';
import { AuthGuard, LoginGuard} from '@app/shared';

const routes: Routes = [
    { path: '', loadChildren: './welcome/welcome.module#WelcomeModule'},
    { path: 'lta', loadChildren: './layout-tardia/layout-tardia.module#LayoutTardiaModule', canActivate: [AuthGuard] },
    { path: 'lte', loadChildren: './layout-temprana/layout-temprana.module#LayoutTempranaModule' },
    { path: 'welcome', loadChildren: './welcome/welcome.module#WelcomeModule' },
    { path: 'login', loadChildren: './login/login.module#LoginModule', canActivate: [LoginGuard] },
    { path: 'signup', loadChildren: './signup/signup.module#SignupModule' },
    { path: 'log', loadChildren: './log/log.module#LogModule' },
    { path: 'rcmensajes', loadChildren: './rcmensajes/rcmensajes.module#RcMensajesModule' },
    { path: 'access-denied', loadChildren: './access-denied/access-denied.module#AccessDeniedModule' },        
    { path: 'not-found', loadChildren: './not-found/not-found.module#NotFoundModule' },    
    { path: '**', redirectTo: 'not-found' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}

