import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { faHandshake } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import { faMoneyCheck } from '@fortawesome/free-solid-svg-icons';
import { faHourglassEnd } from '@fortawesome/free-solid-svg-icons';
import { faCalculator } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-stat-deudas',
    templateUrl: './stat-deudas.component.html',
    styleUrls: ['./stat-deudas.component.scss']
})
export class StatDeudasComponent implements OnInit {
    @Input() bgClass: string;
    @Input() icon: string;
    @Input() count: string;
    @Input() money: string;
    @Input() label: string;
    @Input() info: string;
    @Input() uri: string;
    @Output() event: EventEmitter<any> = new EventEmitter();    
    constructor(public router: Router, private translate: TranslateService) {
        this.translate.addLangs(['en', 'fr', 'ur', 'es-es', 'it', 'fa', 'de', 'es-ar']);
        var location = sessionStorage.getItem('location');
        console.log(location);
        this.translate.use(location);
    }
    public fontIcon;
    ngOnInit() {
        switch (this.icon) {
            case "faHandshake":
                this.fontIcon = faHandshake;
                break;
            case "faEdit":
                this.fontIcon = faEdit;
                break;
            case "faComments":
                this.fontIcon = faComments;
                break;
            case "faMoneyCheck":
                this.fontIcon = faMoneyCheck;
                break;
            case "faHourglassEnd":
                this.fontIcon = faHourglassEnd;
                break;  
            case "faCalculator":
                this.fontIcon = faCalculator;              
            default:
                break;
        }
    }
    onSelect() {        
    }
    
}
